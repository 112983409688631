<template>
  <form class="form-cms">
    <div class="fields-flex mt-1">
      <div class="form-control">
        <label for="cant-equivalence">Cantidad:</label>
        <input type="number" name="cant-equivalence" placeholder="Cantidad" />
      </div>
      <div class="form-control">
        <label for="unit-equivalence">Unidad:</label>
        <input
          type="text"
          name="unit-equivalence"
          placeholder="Unidad de medida"
        />
      </div>
      <div class="form-control">
        <label for="to-equivalence">Equivale a:</label>
        <input type="number" name="to-equivalence" placeholder="Equivale" />
      </div>
      <div class="form-control">
        <label for="measure-equivalence">Tipo de medida:</label>
        <input type="text" name="measure-equivalence" value="gramos" readonly />
      </div>
    </div>
    <div class="buttons gap-0-5">
      <button class="btn btn-primary btn-medium">
        <i class="fas fa-pen-alt"></i> Actualizar
      </button>
      <button class="btn btn-secondary btn-medium">
        <i class="far fa-trash-alt"></i> Eliminar
      </button>
    </div>
  </form>
</template>
